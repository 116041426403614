.navContainer {
    display: flex;
    padding: 10px 0px 5px 0px;
}

.searchContainer{
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    height: 45px;
    position: relative;
}
.searchContainer img {
    height: 30px;
}
.searchContainer form {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 5px 10px 5px 20px;
}
.searchContainer form input {
    border: none;
    background: transparent;
    width: 100%;
    height: 100%;
    font-size: 16px;
    padding: 0 15px;
    outline: none;
}
.searchContainer form button {
    height: 100%;
    padding: 0 35px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    background: var(--primary-color-blue);
    color: #fff;
    border: none;
    border-radius: 10px;
}
.searchContainer input::placeholder {
    color: #575353;
}
.controlsContainer {
    width: 250px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0px 35px;
}
.controlsContainer button{
    background: #fff;
    border-radius: 50%;
    padding: 5px;
    height: 45px; 
    width: 45px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.controlsContainer button img {
    height: 30px;
}
.controlsContainer button span {
    display: flex;
    position: absolute;
    padding: 1px 4px;
    align-items: center;
    justify-content: center;
    top: 10px;
    right: 8px;
    background: #EB5757;
    border-radius: 3px;
    color: #fff;
    font-size: 10px;
}
.dropdown {
    display: flex;
    position: absolute;
    flex-direction: column;
    width: 200px;
    padding: 10px 15px;
    background-color: #fff;
    right: 25px;
    top: 80px;
    box-shadow: 0px 4px 4px rgba(8, 98, 160, 0.05);
    border-radius: 10px;
    z-index: 99999;
}
.dropdown button{
    border: none;
    background: transparent;
    color: #8c8888;
    display: flex;
    justify-content: left;
    padding: 9px 10px;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
}
.dropdown button:hover {
    background: #f5f5f5;
}
.dropdown button img {
    padding-right: 5px;
}