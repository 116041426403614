.firstSection {
    display: flex;
    height: auto;
    padding: 120px 0 30px 0;
    width: 90%;
    margin: 0 auto;

    article {
        width: 50%;

        h1 {
            font-size: 50px;
            color: #0B3A5A;
            line-height: 70px;
            text-transform: uppercase;
        };

        p {
            color: #494848;
            font-size: 15px;
            width: 80%;
        };

        button {
            background:  #0B3A5A;
            color: #f7f7f7;
            border: none;
            font-size: 16px;
            padding: 10px 20px;
            font-weight: 600;
            cursor: pointer;
        };
    };


    .firstRight {
        width: 50%;
        position: relative;

        .firstImageCont {
            display: flex;
            position: relative;
            width: 100%;

            img {
                position: absolute;
                width: 80%;
                width: auto;
                // background: red;

                &:nth-child(1) {
                    height: 500px;
                    left: -28%;
                    top: -95px;
                };

                &:nth-child(2) {
                    left: 6%;
                    top: -6px;
                };

                &:nth-child(3) {
                    left: 10%;
                    top: 25px;
                    width: 450px;
                };
            };
        };
        
        .noteCard{
            position: absolute;
            left: -10px;
            top: 25px;
            z-index: 5;
            background: #fff;
            display: flex;
            flex-direction: column;
            border-radius: 10px;
            width: 250px;
            padding: 5px 15px;

            span:nth-child(1) {
                font-size: 14px;
                color: #494848;
            };
            span:nth-child(2) {
                font-size: 20px;
                color: #0B3A5A;
            };
        };
    };
};


.secondSection {

    width: 100%;
    padding: 30px 0;
    background: rgba(180, 200, 214, 0.2);
    position: relative;

    .secondSectionInnerWrapper {
        width: 100%;
        display: flex;

        .secondImageCont {
            width: 40%;
            position: relative;
            height: 500px;
            display: flex;
            align-items: center;
            justify-content: center;
        
            img {
                display: flex;
                position: absolute;
                height: 60%;
            
                &:nth-child(1){
                    right: 50px;
                    top: 30px;
                    height: 30%;
                };
                &:nth-child(2){
                    right: 195px;
                    height: 50%;
                };
                &:nth-child(3){
                    right: 80px;
                    height: 80%;
                };
                &:nth-child(4){
                    right: 20px;
                    height: 65%;
                };
            };
        };

        article {
            width: 60%;
            padding-right: 70px;
            h1 {
                margin-top: 20px;
                color: #F0F4F7;
                font-size: 120px;
                line-height: 1;
                padding-left: 20px;
                text-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
            };
        
            p {
                font-size: 16px;
                padding-left: 20px;
            };
        
            button {
                margin-left: 20px;
                background:  #0B3A5A;
                color: #f7f7f7;
                border: none;
                font-size: 16px;
                padding: 10px 20px;
                font-weight: 600;
                cursor: pointer;
            };
        };
    };

    .absoluteP {
        position: absolute;
        left: 0;
        bottom: -150px;
    }
}


.thirdSection {
    display: flex;
    padding: 40px 5%;
    position: relative;

    .thirdSectionInnerWrapper {
        width: 100%;
        display: flex;
        
        .thirdSectionACont{
            width: 55%;
            margin-right: 50px;
            padding-left: 20px;
            display: flex;
            justify-content: center;
            flex-direction: column;
        
            article {
                h1 {
                    font-size: 40px;
                    font-weight: 600;
                    color: #0B3A5A;
                };
        
                p {
                    width: 95%;
                };
        
                button {
                    background:  #0B3A5A;
                    color: #f7f7f7;
                    border: none;
                    font-size: 16px;
                    padding: 10px 20px;
                    font-weight: 600;
                    margin-top: 20px;
                    cursor: pointer;
                };
            };
        };

        .imageWrapper {
            width: 45%
        };
    };
    
    .thirdPoly {
        position: absolute;
        left: 0;
        bottom: 30px;
    };
};


.fourthSection {
    background: #f7f7f7;
    padding: 40px 0;

    h1 {
        text-align: center;
        color: #0B3A5A;
        font-size: 40px;
    };

    .fourthSecGrid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: max-content;
        column-gap: 25px;
        row-gap: 25px;
        margin: 0 auto;
        padding-top: 10px;

        .OfferComp {
            background: #FFFFFF;
            box-shadow: 0px 0px 0px 3px rgba(11, 58, 90, 0.1), 0px 1px 4px rgba(11, 58, 90, 0.01);
            border-radius: 16px;
            padding: 15px;

            h3 {
                margin-top: 10px;
                font-size: 18px;
            };

            p {
                font-size: 14px;
                color: #494848;
                line-height: 1.8
            };
        };
    };
};


.fifthSection {
    padding: 30px 5%;

    h1 {
        color: #0B3A5A;
        font-size: 40px;
        text-align: center;
    };

    .shortDash {
        display: block;
        background: #F3BA2F;
        height: 2px;
        width: 145px;
        margin: 20px auto;
    };

    .lHeading {
        width: 450px;
        margin: 0 auto;
        font-size: 14px;
        text-align: center;
    };

    .questContainer {
        background: #F1F1F1;
        box-shadow: 0px 0px 0px 1px rgba(27, 31, 35, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.02);
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 30px auto;
        color: #2F2E2E;

        button {
            width: 100%;
            height: 50px;
            background: #F1F1F1;
            border: none;
            text-align: left;
            padding: 0 15px;
            font-size: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #2F2E2E;
            cursor: pointer;
        };

        div {
            padding: 15px;
            border-top: 1px solid rgba(0, 0, 0, 0.3);
            font-size: 14px;
        };
    };
}


.sixthSection {
    width: 100%;
    padding: 30px 0;
    overflow-x: hidden;

    h1 {
        color: #0B3A5A;
        font-size: 40px;
        text-align: center;
    };
    .remarkItemsWrapper {
        width: 100%;
        padding: 15px;
        display: flex;
        gap: 20px;

        .remarkItem {
            width: 445px;
            min-width: 445px;
            background: #FFFFFF;
            box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            padding: 20px 25px;
            
            h3 {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.5px;
                text-transform: capitalize;
                color: #000000;
            };

            img {
                margin-right: 5px;
            };

            p {
                margin-top: 20px;
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 24px;
                letter-spacing: 0.5px;
                text-transform: capitalize;
                color: #5B6674;
            };
        };
    };
    
    .arrowWrapper {
        margin-top: 40px;
        display: flex;
        justify-content: flex-end;
        gap: 20px;

        .prevButton, .nextButton {
            height: 24px;
            width: 24px;
            background: #E5E5E5;
            
            display: flex;
            align-items: center;
            justify-content: center;
        };
    };
};


.seventhSection {
        margin-top: 50px;

    .seventhSectionInnerWrapper {
        width: 95%;
        margin: 0 auto;
        background: #0B3A5A;
        border-radius: 15px;
        padding: 15px 0 10px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;

        .whiteCircleRing {
            width: 70px;
            position: absolute;
            top: 0;
            right: 0;
        };

        h1 {
            font-style: normal;
            font-weight: normal;
            font-size: 28px;
            line-height: 56px;
            text-align: center;
            text-transform: capitalize;
            color: #F1F1F1;
        };

        form {
            .inputAndButtonWrapper {
                border: 1px solid #F1F1F1;
                box-sizing: border-box;
                border-radius: 3px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                input {
                    border: none;
                    background: transparent;
                    padding: 5px 10px;
                    color: #F1F1F1;
                    line-height: 1.6;

                    &:focus {
                        outline: none
                    };
                };

                button {
                    border: none;
                    background: #FF9900;
                    color: #F7F7F7;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.5px;
                    padding: 7px 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                };
            };
        };
    };
};


.eightSection {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        color: #0B3A5A;
        font-size: 2.5rem;
        font-weight: 500;
        line-height: 1.43;
        text-align: center
    };

    .blogItemsWrapper {
            margin: 10px auto;
            padding-bottom: 20px;
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            grid-template-rows: min-content;
            gap: 30px 15px;

        .blogItem {
            background: #FFFFFF;            
            box-shadow: 0px 10px 36px rgba(0, 0, 0, 0.16), 0px 0px 0px 1px rgba(0, 0, 0, 0.06);
            padding-bottom: 30px;
            display: flex;
            flex-direction: column;

            .titleAndSubTitleWrapper {
                padding: 15px 15px 0 15px;
                display: flex;
                flex-direction: column;

                strong {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    letter-spacing: 0.15px;
                    text-transform: capitalize;
                    color: #2F2E2E;
                };
                p {
                    margin-top: 10px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.5px;
                    text-transform: capitalize;
                    color: #494848;
                };
            };

            .readMoreButton {
                margin-left: 15px;
                align-self: flex-start;
                background: #0B3A5A;
                border: 3px solid #0B3A5A;
                box-sizing: border-box;
                border-radius: 3px;
                color: #FFFFFF;
                cursor: pointer;
                padding: 8px 20px;
            };
        };
    };
};










@media screen and (min-width: 1441px) {
 
}


@media screen and (max-width: 1440px) {

    .firstSection {
        display: flex;
        justify-content: center;
        height: auto;
        padding: 120px 0 50px 0;
        width: 90%;
        margin: 0 auto;
    
        article {
            width: 60%;
            padding-left: 80px;
            padding-right: 90px;
    
            button {
                background:  #0B3A5A;
                color: #f7f7f7;
                border: none;
                font-size: 16px;
                padding: 10px 20px;
                font-weight: 600;
                cursor: pointer;
            };
        };
    
    
        .firstRight {
            width: 40%;
            position: relative;
    
            .firstImageCont {
                display: flex;
                position: relative;
                width: 100%;
    
                img {
                    position: absolute;
                    width: 80%;
                    width: auto;
    
                    &:nth-child(1) {
                        height: 500px;
                        left: -38%;
                        top: -95px;
                    };
    
                    &:nth-child(2) {
                        left: -4%;
                        top: -6px;
                    };
    
                    &:nth-child(3) {
                        left: -2%;
                        top: 25px;
                        width: 450px;
                    };
                };
            };
            
            .noteCard{
                position: absolute;
                left: -16%;
                top: 25px;
                z-index: 5;
                background: #fff;
                display: flex;
                flex-direction: column;
                border-radius: 10px;
                width: 250px;
                padding: 5px 15px;
    
                span:nth-child(1) {
                    font-size: 14px;
                    color: #494848;
                };
                span:nth-child(2) {
                    font-size: 20px;
                    color: #0B3A5A;
                };
            };
        };
    };

    .secondSection {
        .secondSectionInnerWrapper {
            width: 100%;
            display: flex;

            article {
                width: 60%;
                padding-right: 0;
            };
        };
    }
}


@media screen and (max-width: 1024px) {
    .firstSection {
        display: flex;
        height: auto;
        padding: 120px 0 50px 0;
        width: 90%;
        margin: 0 auto;
    
        article {
            width: 65%;
            padding-left: 0;
    
            h1 {
                font-size: 35px;
                color: #0B3A5A;
                line-height: 50px;
                text-transform: uppercase;
            };
    
            p {
                color: #494848;
                font-size: 12px;
                width: 80%;
            };
    
            button {
                background:  #0B3A5A;
                color: #f7f7f7;
                border: none;
                font-size: 16px;
                padding: 10px 20px;
                font-weight: 600;
                cursor: pointer;
            };
        };
    
    
        .firstRight {
            width: 35%;
            position: relative;
    
            .firstImageCont {
                display: flex;
                position: relative;
                width: 100%;
    
                img {
                    position: absolute;
                    width: 80%;
                    width: auto;
                    // background: red;
    
                    &:nth-child(1) {
                        height: 450px;
                        left: -95%;
                        top: -95px;
                    };
    
                    &:nth-child(2) {
                        left: -28%;
                        top: -6px;
                    };
    
                    &:nth-child(3) {
                        left: -22%;
                        top: 25px;
                        width: 360px;
                    };
                };
            };
            
            .noteCard{
                position: absolute;
                left: -46%;
                top: 25px;
                z-index: 5;
                background: #fff;
                display: flex;
                flex-direction: column;
                border-radius: 10px;
                width: 250px;
                padding: 5px 15px;
    
                span:nth-child(1) {
                    font-size: 14px;
                    color: #494848;
                };
                span:nth-child(2) {
                    font-size: 20px;
                    color: #0B3A5A;
                };
            };
        };
    };
        
    .secondSection {
        width: 100%;
        padding-top: 30px;
        background: rgba(180, 200, 214, 0.2);
        position: relative;

        .secondSectionInnerWrapper {
            width: 100%;
            display: flex;

            .secondImageCont {
                width: 45%;
                position: relative;
                height: 380px;
                display: flex;
                align-items: center;
                justify-content: center;
            
                img {
                    display: flex;
                    position: absolute;
                    height: 60%;
                
                    &:nth-child(1){
                        right: 60px;
                        top: 30px;
                        height: 27%;
                    };
                    &:nth-child(2){
                        top: 90px;
                        right: 125px;
                        height: 63%;
                    };
                    &:nth-child(3){
                        top: 30px;
                        right: 90px;
                        height: 85%;
                    };
                    &:nth-child(4){
                        top: 60px;
                        right: 20px;
                        height: 73%;
                    };
                };
            };

            article {
                width: 55%;
                padding-right: 0;
                h1 {
                    margin-top: 20px;
                    color: #F0F4F7;
                    font-size: 100px;
                    line-height: 1;
                    padding-left: 0;
                    text-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
                };
            
                p {
                    font-size: 14px;
                    padding-left: 0;
                };
            
                button {
                    margin-left: 0;
                    background:  #0B3A5A;
                    color: #f7f7f7;
                    border: none;
                    font-size: 16px;
                    padding: 10px 20px;
                    font-weight: 600;
                    cursor: pointer;
                };
            };
        };

        .absoluteP {
            width: 170px;
            position: absolute;
            left: 0;
            bottom: -130px;
        }
    };

    .thirdSection {
        width: 100%;
        display: flex;
        padding: 40px 5%;
        position: relative;
    
        .thirdSectionInnerWrapper {
            width: 100%;
            display: flex;
            
            .thirdSectionACont{
                width: 55%;
                margin-right: 10px;
                padding-left: 5px;
                display: flex;
                justify-content: center;
                flex-direction: column;
            
                article {
                    h1 {
                        font-size: 30px;
                        font-weight: 600;
                        color: #0B3A5A;
                    };
            
                    p {
                        width: 95%;
                        font-size: 14px
                    };
            
                    button {
                        background:  #0B3A5A;
                        color: #f7f7f7;
                        border: none;
                        font-size: 16px;
                        padding: 10px 20px;
                        font-weight: 600;
                        margin-top: 20px;
                        cursor: pointer;
                    };
                };
            };
    
            .imageWrapper {
                width: 45%;

                img {
                    width: 100%
                };
            };
        };
        
        .thirdPoly {
            width: 170px;
            position: absolute;
            left: 0;
            bottom: 30px;
        };
    };

    .fourthSection {
        background: #f7f7f7;
        padding: 40px 0;
    
        h1 {
            text-align: center;
            color: #0B3A5A;
            font-size: 30px;
        };
    
        .fourthSecGrid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: max-content;
            column-gap: 25px;
            row-gap: 25px;
            margin: 0 auto;
            padding-top: 10px;
    
            .OfferComp {
                background: #FFFFFF;
                box-shadow: 0px 0px 0px 3px rgba(11, 58, 90, 0.1), 0px 1px 4px rgba(11, 58, 90, 0.01);
                border-radius: 16px;
                padding: 15px;
    
                h3 {
                    margin-top: 10px;
                    font-size: 16px;
                };
    
                p {
                    font-size: 13px !important;
                    color: #494848;
                    line-height: 1.8;
                };
            };
        };
    };

    .fifthSection {
        padding: 30px 5%;

        h1 {
            color: #0B3A5A;
            font-size: 30px;
            text-align: center;
        };
    };

    .sixthSection {
        padding: 30px 0;

        h1 {
            color: #0B3A5A;
            font-size: 30px;
            text-align: center;
        };
        .remarkItemsWrapper {
            padding: 15px;
            display: flex;
            gap: 20px;
            overflow-x: hidden;

            .remarkWrapper {
                width: 400px;
                min-width: 400px;
                background: #FFFFFF;
                box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
                border-radius: 5px;
                padding: 20px 25px 10px 25px;

                h3 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 24px;
                    letter-spacing: 0.5px;
                    text-transform: capitalize;
                    color: #000000;
                };

                p {
                    margin-top: 20px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 20px;
                    letter-spacing: 0.5px;
                    text-transform: capitalize;
                    color: #5B6674;
                };
            };
        };
        
        .arrowWrapper {
            margin-top: 40px;
            display: flex;
            justify-content: flex-end;
            gap: 20px;

            span {
                height: 24px;
                width: 24px;
                background: #E5E5E5;
                
                display: flex;
                align-items: center;
                justify-content: center;
            };
        };
    };

    .eightSection {
        margin-top: 70px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;


        h1 {
            color: #0B3A5A;
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1.43;
            text-align: center
        };

        .blogItemsWrapper {
                width: 100%;
                margin: 25px auto;
                padding-bottom: 20px;
                display: grid;
                grid-template-columns: repeat(3, minmax(0, 1fr));
                grid-template-rows: min-content;
                gap: 30px 15px;

            .blogItem {
                background: #FFFFFF;            
                box-shadow: 0px 10px 36px rgba(0, 0, 0, 0.16), 0px 0px 0px 1px rgba(0, 0, 0, 0.06);
                padding-bottom: 30px;
                display: flex;
                flex-direction: column;

                .titleAndSubTitleWrapper {
                    padding: 10px;
                    display: flex;
                    flex-direction: column;

                    strong {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: 0.15px;
                        text-transform: capitalize;
                        color: #2F2E2E;
                    };
                    p {
                        margin-top: 10px;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 9px;
                        line-height: 17px;
                        letter-spacing: 0.5px;
                        text-transform: capitalize;
                        color: #494848;
                    };
                };

                .readMoreButton {
                    margin-left: 15px;
                    margin-top: 20px;
                    align-self: flex-start;
                    background: #0B3A5A;
                    border: 3px solid #0B3A5A;
                    box-sizing: border-box;
                    border-radius: 3px;
                    color: #FFFFFF;
                    cursor: pointer;
                    font-size: 8px;
                    padding: 6px 8px;
                };
            };
        };
    };
}


@media screen and (max-width: 991px) {

}


@media screen and (max-width: 768px) {
    .firstSection {
        display: flex;
        height: auto;
        padding: 120px 0 30px 0;
        width: 97%;
        margin: 0 auto;
    
        article {
            width: 65%;
            padding-left: 0;
    
            h1 {
                font-size: 28px;
                color: #0B3A5A;
                line-height: 40px;
                text-transform: uppercase;
            };
    
            p {
                color: #494848;
                font-size: 12px;
                width: 70%;
            };
    
            button {
                background:  #0B3A5A;
                color: #f7f7f7;
                border: none;
                font-size: 16px;
                padding: 10px 20px;
                font-weight: 600;
                cursor: pointer;
            };
        };
    
    
        .firstRight {
            width: 35%;
            position: relative;
    
            .firstImageCont {
                display: flex;
                position: relative;
                width: 100%;
    
                img {
                    position: absolute;
                    width: 100%;
                    width: auto;
    
                    &:nth-child(1) {
                        height: 450px;
                        left: -115%;
                        top: -95px;
                    };
    
                    &:nth-child(2) {
                        left: -45%;
                        top: -6px;
                    };
    
                    &:nth-child(3) {
                        left: -38%;
                        top: 25px;
                        width: 330px;
                    };
                };
            };
            
            .noteCard{
                position: absolute;
                left: -64%;
                top: 25px;
                z-index: 5;
                background: #fff;
                display: flex;
                flex-direction: column;
                border-radius: 10px;
                width: 200px;
                padding: 5px 15px;
    
                span:nth-child(1) {
                    font-size: 12px;
                    color: #494848;
                };
                span:nth-child(2) {
                    font-size: 18px;
                    color: #0B3A5A;
                };
            };
        };
    };
        
    .secondSection {
        width: 100%;
        padding-top: 20px;
        background: rgba(180, 200, 214, 0.2);
        position: relative;

        .secondSectionInnerWrapper {
            width: 100%;
            display: flex;

            .secondImageCont {
                width: 47%;
                position: relative;
                height: 340px;
                display: flex;
                align-items: center;
                justify-content: center;
            
                img {
                    display: flex;
                    position: absolute;
                    height: 60%;
                
                    &:nth-child(1){
                        right: 60px;
                        top: 33px;
                        height: 25%;
                    };
                    &:nth-child(2){
                        top: 90px;
                        right: 115px;
                        height: 55%;
                    };
                    &:nth-child(3){
                        top: 30px;
                        right: 90px;
                        height: 75%;
                    };
                    &:nth-child(4){
                        top: 60px;
                        right: 20px;
                        height: 65%;
                    };
                };
            };

            article {
                width: 53%;
                padding-right: 0;
                h1 {
                    margin-top: 20px;
                    color: #F0F4F7;
                    font-size: 80px;
                    line-height: 1;
                    padding-left: 0;
                    text-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
                };
            
                p {
                    font-size: 12px;
                    padding-left: 0;
                };
            
                button {
                    margin-left: 0;
                    background:  #0B3A5A;
                    color: #f7f7f7;
                    border: none;
                    font-size: 14px;
                    padding: 8px 18px;
                    font-weight: 600;
                    cursor: pointer;
                };
            };
        };

        .absoluteP {
            width: 150px;
            position: absolute;
            left: 0;
            bottom: -115px;
        }
    };

    .thirdSection {
        width: 100%;
        display: flex;
        padding: 40px 5%;
        position: relative;
    
        .thirdSectionInnerWrapper {
            width: 100%;
            display: flex;
            
            .thirdSectionACont{
                width: 55%;
                margin-right: 10px;
                padding-left: 5px;
                display: flex;
                justify-content: center;
                flex-direction: column;
            
                article {
                    h1 {
                        font-size: 28px;
                        font-weight: 600;
                        color: #0B3A5A;
                    };
            
                    p {
                        width: 95%;
                        font-size: 12px
                    };
            
                    button {
                        background:  #0B3A5A;
                        color: #f7f7f7;
                        border: none;
                        font-size: 16px;
                        padding: 10px 20px;
                        font-weight: 600;
                        margin-top: 0;
                        cursor: pointer;
                    };
                };
            };
    
            .imageWrapper {
                width: 45%;

                img {
                    width: 100%
                };
            };
        };
        
        .thirdPoly {
            width: 170px;
            position: absolute;
            left: 0;
            bottom: 30px;
        };
    };

    .fourthSection {
        background: #f7f7f7;
        padding: 40px 0;
    
        h1 {
            text-align: center;
            color: #0B3A5A;
            font-size: 28px;
        };
    
        .fourthSecGrid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: max-content;
            column-gap: 25px;
            row-gap: 25px;
            margin: 0 auto;
            padding-top: 10px;
    
            .OfferComp {
                background: #FFFFFF;
                box-shadow: 0px 0px 0px 3px rgba(11, 58, 90, 0.1), 0px 1px 4px rgba(11, 58, 90, 0.01);
                border-radius: 16px;
                padding: 15px 15px 10px 15px ;
    
                h3 {
                    margin-top: 10px;
                    font-size: 16px;
                };
    
                p {
                    font-size: 12px !important;
                    color: #494848;
                    line-height: 1.6;
                };
            };
        };
    };

    .fifthSection {
        padding: 30px 5%;

        h1 {
            color: #0B3A5A;
            font-size: 28px;
            text-align: center;
        };
    };

    .sixthSection {
        padding: 30px 0;

        h1 {
            color: #0B3A5A;
            font-size: 28px;
            text-align: center;
        };
        .remarkItemsWrapper {
            padding: 15px;
            display: flex;
            gap: 20px;
            overflow-x: hidden;

            .remarkWrapper {
                width: 350px;
                min-width: 350px;
                background: #FFFFFF;
                box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
                border-radius: 5px;
                padding: 15px 20px 5px 20px;

                h3 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 16px;
                    letter-spacing: 0.5px;
                    text-transform: capitalize;
                    color: #000000;
                };

                img {
                    width: 18px;
                    margin-right: 5px;
                };

                p {
                    margin-top: 20px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 20px;
                    letter-spacing: 0.5px;
                    text-transform: capitalize;
                    color: #5B6674;
                };
            };
        };
        
        .arrowWrapper {
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;
            gap: 20px;

            span {
                height: 24px;
                width: 24px;
                background: #E5E5E5;
                
                display: flex;
                align-items: center;
                justify-content: center;
            };
        };
    };

    .seventhSection {
            margin-top: 50px;

        .seventhSectionInnerWrapper {
            width: 95%;
            background: #0B3A5A;
            border-radius: 15px;
            padding: 15px 0 10px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
        
            .whiteCircleRing {
                width: 60px;
                position: absolute;
                top: 0;
                right: 0;
            };
        
            h1 {
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                line-height: 36px;
                text-align: center;
                text-transform: capitalize;
                color: #F1F1F1;
            };
        
            form {
                .inputAndButtonWrapper {
                    border: 1px solid #9e9e9e;
                    box-sizing: border-box;
                    border-radius: 3px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
        
                    input {
                        border: none;
                        background: transparent;
                        padding: 5px 10px;
                        color: #F1F1F1;
                        line-height: 1.6;
        
                        &:focus {
                            outline: none
                        };
                    };
        
                    button {
                        border: none;
                        background: #FF9900;
                        color: #F7F7F7;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: 0.5px;
                        padding: 7px 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    };
                };
            };
        }
    };

    .eightSection {
        margin-top: 70px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;


        h1 {
            color: #0B3A5A;
            font-size: 28px;
            font-weight: 500;
            line-height: 1.43;
            text-align: center
        };

        .blogItemsWrapper {
                width: 100%;
                margin: 25px auto;
                padding-bottom: 20px;
                display: grid;
                grid-template-columns: repeat(3, minmax(0, 1fr));
                grid-template-rows: min-content;
                gap: 30px 15px;

            .blogItem {
                background: #FFFFFF;            
                box-shadow: 0px 10px 36px rgba(0, 0, 0, 0.16), 0px 0px 0px 1px rgba(0, 0, 0, 0.06);
                padding-bottom: 30px;
                display: flex;
                flex-direction: column;

                .titleAndSubTitleWrapper {
                    padding: 10px;
                    display: flex;
                    flex-direction: column;

                    strong {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: 0.15px;
                        text-transform: capitalize;
                        color: #2F2E2E;
                    };
                    p {
                        margin-top: 10px;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 9px;
                        line-height: 17px;
                        letter-spacing: 0.5px;
                        text-transform: capitalize;
                        color: #494848;
                    };
                };

                .readMoreButton {
                    margin-left: 15px;
                    margin-top: 20px;
                    align-self: flex-start;
                    background: #0B3A5A;
                    border: 3px solid #0B3A5A;
                    box-sizing: border-box;
                    border-radius: 3px;
                    color: #FFFFFF;
                    cursor: pointer;
                    font-size: 8px;
                    padding: 6px 8px;
                };
            };
        };
    };
}


@media screen and (max-width: 575px) {
    .firstSection {
        display: flex;
        flex-direction: column;
        height: auto;
        padding: 120px 0 30px 0;
        width: 98%;
        margin: 0 auto;
    
        article {
            width: 100%;
            padding-left: 0;
    
            h1 {
                font-size: 24px;
                color: #0B3A5A;
                line-height: 30px;
                text-transform: uppercase;
            };
    
            p {
                color: #494848;
                font-size: 12px;
                width: 100%;
            };
    
            button {
                background:  #0B3A5A;
                color: #f7f7f7;
                border: none;
                font-size: 14px;
                padding: 8px 15px;
                font-weight: 600;
                cursor: pointer;
            };
        };
    
    
        .firstRight {
            width: 100%;
            position: relative;
    
            .firstImageCont {
                height: 340px;
                width: 100%;
                margin-top: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
    
                img {
                    position: absolute;
    
                    &:nth-child(1) {
                        height: 450px;
                        left: -20%;
                        top: -55px;
                    };
    
                    &:nth-child(2) {
                        width: 200px;
                        left: 12%;
                        top: 9px;
                    };
    
                    &:nth-child(3) {
                        width: 380px;
                        left: 15%;
                        top: 40px;
                    };
                };
            };
            
            .noteCard{
                position: absolute;
                left: 0%;
                top: 60px;
                z-index: 5;
                background: #fff;
                display: flex;
                flex-direction: column;
                border-radius: 8px;
                width: 250px;
                padding: 7px 15px;
    
                span:nth-child(1) {
                    font-size: 12px;
                    color: #494848;
                };
                span:nth-child(2) {
                    font-size: 18px;
                    color: #0B3A5A;
                };
            };
        };
    };
        
    .secondSection {
        width: 100%;
        margin: 0 auto;
        padding-top: 20px;
        background: rgba(180, 200, 214, 0.2);
        position: relative;

        .secondSectionInnerWrapper {
            width: 97%;
            display: flex;
            flex-direction: column;

            .secondImageCont {
                width: 100%;
                position: relative;
                height: 400px;
                display: flex;
                align-items: center;
                justify-content: center;
                order: 2;
            
                img {
                    display: flex;
                    position: absolute;
                    height: 70%;
                
                    &:nth-child(1){
                        right: 95px;
                        top: 33px;
                        height: 28%;
                    };
                    &:nth-child(2){
                        top: 60px;
                        right: 110px;
                        height: 75%;
                    };
                    &:nth-child(3){
                        top: 30px;
                        right: 135px;
                        height: 85%;
                    };
                    &:nth-child(4){
                        top: 60px;
                        right: 45px;
                        height: 75%;
                    };
                };
            };

            article {
                width: 100%;
                padding-right: 0;
                order: 1;
                h1 {
                    margin-top: 20px;
                    color: #F0F4F7;
                    font-size: 80px;
                    line-height: 1;
                    padding-left: 0;
                    text-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
                };
            
                p {
                    font-size: 12px;
                    padding-left: 0;
                };
            
                button {
                    margin-left: 0;
                    background:  #0B3A5A;
                    color: #f7f7f7;
                    border: none;
                    font-size: 14px;
                    padding: 8px 18px;
                    font-weight: 600;
                    cursor: pointer;
                };
            };
        };

        .absoluteP {
            width: 150px;
            position: absolute;
            left: 0;
            bottom: -115px;
        }
    };

    .thirdSection {
        width: 100%;
        padding: 40px 10px;
        position: relative;
    
        .thirdSectionInnerWrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            
            .thirdSectionACont{
                width: 100%;
                margin-right: 0;
                padding-left: 0;
                display: flex;
                justify-content: center;
                flex-direction: column;
            
                article {
                    h1 {
                        font-size: 28px;
                        font-weight: 500;
                        color: #0B3A5A;
                    };
            
                    p {
                        width: 100%;
                        font-size: 12px
                    };
            
                    button {
                        background:  #0B3A5A;
                        color: #f7f7f7;
                        border: none;
                        font-size: 16px;
                        padding: 10px 20px;
                        font-weight: 600;
                        margin-top: 0;
                        cursor: pointer;
                    };
                };
            };
    
            .imageWrapper {
                width: 100%;
                margin-top: 20px;
                display: flex;
                justify-content: center;

                img {
                    width: 80%
                };
            };
        };
        
        .thirdPoly {
            width: 130px;
            position: absolute;
            left: -60px;
            bottom: 30px;
        };
    };

    .fourthSection {
        background: #f7f7f7;
        padding: 40px 15px;
    
        h1 {
            text-align: center;
            color: #0B3A5A;
            font-size: 28px;
        };
    
        .fourthSecGrid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: max-content;
            column-gap: 15px;
            row-gap: 20px;
            margin: 0 auto;
            padding-top: 10px;
    
            .OfferComp {
                background: #FFFFFF;
                box-shadow: 0px 0px 0px 3px rgba(11, 58, 90, 0.1), 0px 1px 4px rgba(11, 58, 90, 0.01);
                border-radius: 16px;
                padding: 10px 10px 5px 10px ;

                img {
                    width: 25px;
                };
    
                h3 {
                    margin-top: 10px;
                    font-size: 14px;
                };
    
                p {
                    font-size: 12px !important;
                    color: #494848;
                    line-height: 1.5;
                };
            };
        };
    };

    .fifthSection {
        padding: 30px 5%;
    
        h1 {
            color: #0B3A5A;
            font-size: 28px;
            text-align: center;
        };
    
        .shortDash {
            display: block;
            background: #F3BA2F;
            height: 2px;
            width: 90px;
            margin: 10px auto;
        };
    
        .lHeading {
            width: 100%;
            margin: 0 auto;
            font-size: 12px;
            text-align: center;
        };
    
        .questContainer {
            background: #F1F1F1;
            box-shadow: 0px 0px 0px 1px rgba(27, 31, 35, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.02);
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: 25px auto;
            color: #2F2E2E;
    
            button {
                width: 100%;
                height: 50px;
                background: #F1F1F1;
                border: none;
                text-align: left;
                padding: 0 15px;
                font-size: 14px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #2F2E2E;
                cursor: pointer;
            };
        };
    }

    .sixthSection {
        padding: 30px 0;

        h1 {
            color: #0B3A5A;
            font-size: 28px;
            text-align: center;
        };
        .remarkItemsWrapper {
            padding: 15px;
            display: flex;
            gap: 20px;
            overflow-x: hidden;

            .remarkWrapper {
                width: 350px;
                min-width: 350px;
                background: #FFFFFF;
                box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
                border-radius: 5px;
                padding: 15px 20px 5px 20px;

                h3 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 16px;
                    letter-spacing: 0.5px;
                    text-transform: capitalize;
                    color: #000000;
                };

                img {
                    width: 18px;
                    margin-right: 5px;
                };

                p {
                    margin-top: 20px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 20px;
                    letter-spacing: 0.5px;
                    text-transform: capitalize;
                    color: #5B6674;
                };
            };
        };
        
        .arrowWrapper {
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;
            gap: 20px;

            span {
                height: 24px;
                width: 24px;
                background: #E5E5E5;
                
                display: flex;
                align-items: center;
                justify-content: center;
            };
        };
    };

    .seventhSection {
            margin-top: 50px;

        .seventhSectionInnerWrapper {
            width: 95%;
            background: #0B3A5A;
            border-radius: 15px;
            padding: 15px 0 10px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;

            .whiteCircleRing {
                width: 50px;
                position: absolute;
                top: 0;
                right: 0;
            };
        
            h1 {
                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                line-height: 36px;
                text-align: center;
                text-transform: capitalize;
                color: #F1F1F1;
            };
        
            form {
                .inputAndButtonWrapper {
                    border: 1px solid #9e9e9e;
                    box-sizing: border-box;
                    border-radius: 3px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
        
                    input {
                        border: none;
                        background: transparent;
                        padding: 5px 10px;
                        color: #F1F1F1;
                        font-size: 14px;
                        line-height: 1.6;
        
                        &:focus {
                            outline: none
                        };
                    };
        
                    button {
                        border: none;
                        background: #FF9900;
                        color: #F7F7F7;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        letter-spacing: 0.5px;
                        padding: 7px 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    };
                };
            };
        };
    };

    .eightSection {
        margin-top: 70px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;


        h1 {
            color: #0B3A5A;
            font-size: 28px;
            font-weight: 500;
            line-height: 1.43;
            text-align: center
        };

        .blogItemsWrapper {
                width: 100%;
                margin: 25px auto;
                padding-bottom: 20px;
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: min-content;
                gap: 30px 15px;

            .blogItem {
                background: #FFFFFF;            
                box-shadow: 0px 10px 36px rgba(0, 0, 0, 0.16), 0px 0px 0px 1px rgba(0, 0, 0, 0.06);
                padding-bottom: 30px;
                display: flex;
                flex-direction: column;

                .titleAndSubTitleWrapper {
                    padding: 10px;
                    display: flex;
                    flex-direction: column;

                    strong {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: 0.15px;
                        text-transform: capitalize;
                        color: #2F2E2E;
                    };
                    p {
                        margin-top: 10px;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 9px;
                        line-height: 17px;
                        letter-spacing: 0.5px;
                        text-transform: capitalize;
                        color: #494848;
                    };
                };

                .readMoreButton {
                    margin-left: 15px;
                    margin-top: 20px;
                    align-self: flex-start;
                    background: #0B3A5A;
                    border: 3px solid #0B3A5A;
                    box-sizing: border-box;
                    border-radius: 3px;
                    color: #FFFFFF;
                    cursor: pointer;
                    font-size: 8px;
                    padding: 6px 8px;
                };
            };
        };
    };
}


@media screen and (max-width: 425px) {
    .firstSection {
        display: flex;
        flex-direction: column;
        height: auto;
        padding: 120px 0 30px 0;
        width: 98%;
        margin: 0 auto;
    
        article {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            display: block;
    
            h1 {
                font-size: 24px;
                color: #0B3A5A;
                line-height: 30px;
                text-transform: uppercase;
            };
    
            p {
                color: #494848;
                font-size: 12px;
                width: 100%;
            };
    
            button {
                background:  #0B3A5A;
                color: #f7f7f7;
                border: none;
                font-size: 14px;
                padding: 8px 15px;
                font-weight: 600;
                cursor: pointer;
            };
        };
    
    
        .firstRight {
            width: 100%;
            position: relative;
    
            .firstImageCont {
                height: 300px;
                width: 100%;
                margin-top: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
    
                img {
                    position: absolute;
    
                    &:nth-child(1) {
                        height: 400px;
                        left: -20%;
                        top: -55px;
                    };
    
                    &:nth-child(2) {
                        width: 170px;
                        left: 10%;
                        top: 15px;
                    };
    
                    &:nth-child(3) {
                        width: 300px;
                        left: 12%;
                        top: 40px;
                    };
                };
            };
            
            .noteCard{
                position: absolute;
                left: -10px;
                top: 60px;
                z-index: 5;
                background: #fff;
                display: flex;
                flex-direction: column;
                border-radius: 8px;
                width: 220px;
                padding: 7px 15px;
    
                span:nth-child(1) {
                    font-size: 12px;
                    color: #494848;
                };
                span:nth-child(2) {
                    font-size: 18px;
                    color: #0B3A5A;
                };
            };
        };
    };
        
    .secondSection {
        width: 100%;
        margin: 0 auto;
        padding-top: 20px;
        background: rgba(180, 200, 214, 0.2);
        position: relative;

        .secondSectionInnerWrapper {
            width: 100%;
            display: flex;
            flex-direction: column;

            .secondImageCont {
                width: 100%;
                position: relative;
                height: 350px;
                display: flex;
                align-items: center;
                justify-content: center;
                order: 2;
            
                img {
                    display: flex;
                    position: absolute;
                    height: 70%;
                
                    &:nth-child(1){
                        right: 60px;
                        top: 33px;
                        height: 28%;
                    };
                    &:nth-child(2){
                        top: 60px;
                        right: 70px;
                        height: 70%;
                    };
                    &:nth-child(3){
                        top: 30px;
                        right: 95px;
                        height: 83%;
                    };
                    &:nth-child(4){
                        top: 60px;
                        right: 15px;
                        height: 70%;
                    };
                };
            };

            article {
                width: 100%;
                padding-right: 0;
                order: 1;
                h1 {
                    margin-top: 20px;
                    color: #F0F4F7;
                    font-size: 70px;
                    line-height: 1;
                    padding-left: 0;
                    text-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
                };
            
                p {
                    font-size: 12px;
                    padding-left: 0;
                };
            
                button {
                    margin-left: 0;
                    background:  #0B3A5A;
                    color: #f7f7f7;
                    border: none;
                    font-size: 14px;
                    padding: 8px 18px;
                    font-weight: 600;
                    cursor: pointer;
                };
            };
        };

        .absoluteP {
            width: 150px;
            position: absolute;
            left: 0;
            bottom: -115px;
        }
    };

    .thirdSection {
        width: 100%;
        padding: 40px 10px;
        position: relative;
    
        .thirdSectionInnerWrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            
            .thirdSectionACont{
                width: 100%;
                margin-right: 0;
                padding-left: 0;
                display: flex;
                justify-content: center;
                flex-direction: column;
            
                article {
                    h1 {
                        font-size: 28px;
                        font-weight: 500;
                        color: #0B3A5A;
                    };
            
                    p {
                        width: 100%;
                        font-size: 12px
                    };
            
                    button {
                        background:  #0B3A5A;
                        color: #f7f7f7;
                        border: none;
                        font-size: 16px;
                        padding: 10px 20px;
                        font-weight: 600;
                        margin-top: 0;
                        cursor: pointer;
                    };
                };
            };
    
            .imageWrapper {
                width: 100%;
                margin-top: 20px;
                display: flex;
                justify-content: center;

                img {
                    width: 85%
                };
            };
        };
        
        .thirdPoly {
            width: 130px;
            position: absolute;
            left: -60px;
            bottom: 30px;
        };
    };

    .fourthSection {
        background: #f7f7f7;
        padding: 40px 15px;
    
        h1 {
            text-align: center;
            color: #0B3A5A;
            font-size: 28px;
        };
    
        .fourthSecGrid {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: max-content;
            column-gap: 15px;
            row-gap: 20px;
            margin: 0 auto;
            padding-top: 10px;
    
            .OfferComp {
                background: #FFFFFF;
                box-shadow: 0px 0px 0px 3px rgba(11, 58, 90, 0.1), 0px 1px 4px rgba(11, 58, 90, 0.01);
                border-radius: 16px;
                padding: 10px 10px 5px 10px ;

                img {
                    width: 25px;
                };
    
                h3 {
                    margin-top: 10px;
                    font-size: 14px;
                };
    
                p {
                    font-size: 12px !important;
                    color: #494848;
                    line-height: 1.5;
                };
            };
        };
    };

    .fifthSection {
        padding: 30px 5%;
    
        h1 {
            color: #0B3A5A;
            font-size: 28px;
            text-align: center;
        };
    
        .shortDash {
            display: block;
            background: #F3BA2F;
            height: 2px;
            width: 90px;
            margin: 10px auto;
        };
    
        .lHeading {
            width: 100%;
            margin: 0 auto;
            font-size: 12px;
            text-align: center;
        };
    
        .questContainer {
            background: #F1F1F1;
            box-shadow: 0px 0px 0px 1px rgba(27, 31, 35, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.02);
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: 25px auto;
            color: #2F2E2E;
    
            button {
                width: 100%;
                height: 50px;
                background: #F1F1F1;
                border: none;
                text-align: left;
                padding: 0 15px;
                font-size: 14px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #2F2E2E;
                cursor: pointer;
            };
        };
    }

    .sixthSection {
        padding: 30px 0;

        h1 {
            color: #0B3A5A;
            font-size: 28px;
            text-align: center;
        };
        .remarkItemsWrapper {
            padding: 15px 10px;
            display: flex;
            gap: 20px;
            overflow-x: hidden;

            .remarkWrapper {
                width: 300px;
                min-width: 300px;
                background: #FFFFFF;
                box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
                border-radius: 5px;
                padding: 15px 15px 0 15px;

                h3 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 16px;
                    letter-spacing: 0.5px;
                    text-transform: capitalize;
                    color: #000000;
                };

                img {
                    width: 16px;
                    margin-right: 5px;
                };

                p {
                    margin-top: 15px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 20px;
                    letter-spacing: 0.5px;
                    text-transform: capitalize;
                    color: #5B6674;
                };
            };
        };
        
        .arrowWrapper {
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;
            gap: 20px;

            span {
                height: 24px;
                width: 24px;
                background: #E5E5E5;
                
                display: flex;
                align-items: center;
                justify-content: center;
            };
        };
    };

    .seventhSection {
            margin-top: 50px;

        .seventhSectionInnerWrapper {
            width: 100%;
            background: #0B3A5A;
            border-radius: 15px;
            padding: 15px 0 10px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;

            .whiteCircleRing {
                width: 45px;
                position: absolute;
                top: 0;
                right: 0;
            };
        
            h1 {
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 36px;
                text-align: center;
                text-transform: capitalize;
                color: #F1F1F1;
            };
        
            form {
                .inputAndButtonWrapper {
                    border: 1px solid #9e9e9e;
                    box-sizing: border-box;
                    border-radius: 3px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
        
                    input {
                        border: none;
                        background: transparent;
                        padding: 3px 10px;
                        color: #F1F1F1;
                        font-size: 12px;
                        line-height: 1.4;
        
                        &:focus {
                            outline: none
                        };
                    };
        
                    button {
                        border: none;
                        background: #FF9900;
                        color: #F7F7F7;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 24px;
                        letter-spacing: 0.5px;
                        padding: 5px 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    };
                };
            };
        };
    };

    .eightSection {
        margin-top: 70px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;


        h1 {
            color: #0B3A5A;
            font-size: 28px;
            font-weight: 500;
            line-height: 1.3;
            text-align: center
        };

        .blogItemsWrapper {
                width: 100%;
                margin: 20px auto;
                padding-bottom: 10px;
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: min-content;
                gap: 25px;

            .blogItem {
                background: #FFFFFF;            
                box-shadow: 0px 10px 36px rgba(0, 0, 0, 0.16), 0px 0px 0px 1px rgba(0, 0, 0, 0.06);
                padding-bottom: 20px;
                display: flex;
                flex-direction: column;

                .titleAndSubTitleWrapper {
                    padding: 10px 10px 0 10px;
                    display: flex;
                    flex-direction: column;

                    strong {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: 0.15px;
                        text-transform: capitalize;
                        color: #2F2E2E;
                    };
                    p {
                        margin-top: 10px;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 9px;
                        line-height: 17px;
                        letter-spacing: 0.5px;
                        text-transform: capitalize;
                        color: #494848;
                    };
                };

                .readMoreButton {
                    margin-left: 10px;
                    margin-top: 0;
                    align-self: flex-start;
                    background: #0B3A5A;
                    border: 3px solid #0B3A5A;
                    box-sizing: border-box;
                    border-radius: 3px;
                    color: #FFFFFF;
                    cursor: pointer;
                    font-size: 8px;
                    padding: 6px 8px;
                };
            };
        };
    };
}


@media screen and (max-width: 375px) {
    .firstSection {
        display: flex;
        flex-direction: column;
        height: auto;
        padding: 120px 0 30px 0;
        width: 100%;
        margin: 0 auto;
    
        article {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
    
            h1 {
                font-size: 24px;
                color: #0B3A5A;
                line-height: 30px;
                text-transform: uppercase;
            };
    
            p {
                color: #494848;
                font-size: 12px;
                width: 100%;
            };
    
            button {
                background:  #0B3A5A;
                color: #f7f7f7;
                border: none;
                font-size: 14px;
                padding: 8px 15px;
                font-weight: 600;
                cursor: pointer;
            };
        };
    
    
        .firstRight {
            width: 100%;
            position: relative;
    
            .firstImageCont {
                height: 300px;
                width: 100%;
                margin-top: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
    
                img {
                    position: absolute;
    
                    &:nth-child(1) {
                        height: 330px;
                        left: -20%;
                        top: -35px;
                    };
    
                    &:nth-child(2) {
                        width: 170px;
                        left: 10%;
                        top: 15px;
                    };
    
                    &:nth-child(3) {
                        width: 240px;
                        left: 12%;
                        top: 40px;
                    };
                };
            };
            
            .noteCard{
                position: absolute;
                left: -15px;
                top: 60px;
                z-index: 5;
                background: #fff;
                display: flex;
                flex-direction: column;
                border-radius: 8px;
                width: 180px;
                padding: 5px 10px;
    
                span:nth-child(1) {
                    font-size: 11px;
                    color: #494848;
                };
                span:nth-child(2) {
                    font-size: 16px;
                    color: #0B3A5A;
                };
            };
        };
    };
    
        
    .secondSection {
        width: 100%;
        margin: 0 auto;
        padding-top: 20px;
        background: rgba(180, 200, 214, 0.2);
        position: relative;

        .secondSectionInnerWrapper {
            width: 100%;
            display: flex;
            flex-direction: column;

            .secondImageCont {
                width: 100%;
                position: relative;
                height: 350px;
                display: flex;
                align-items: center;
                justify-content: center;
                order: 2;
            
                img {
                    display: flex;
                    position: absolute;
                    height: 70%;
                
                    &:nth-child(1){
                        right: 40px;
                        top: 53px;
                        height: 28%;
                    };
                    &:nth-child(2){
                        top: 83px;
                        right: 38px;
                        height: 58%;
                    };
                    &:nth-child(3){
                        top: 50px;
                        right: 75px;
                        height: 69%;
                    };
                    &:nth-child(4){
                        top: 80px;
                        right: 0px;
                        height: 57%;
                    };
                };
            };

            article {
                width: 100%;
                padding-right: 0;
                order: 1;
                h1 {
                    margin-top: 20px;
                    color: #F0F4F7;
                    font-size: 60px;
                    line-height: 1;
                    padding-left: 0;
                    text-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
                };
            
                p {
                    font-size: 12px;
                    padding-left: 0;
                };
            
                button {
                    margin-left: 0;
                    background:  #0B3A5A;
                    color: #f7f7f7;
                    border: none;
                    font-size: 14px;
                    padding: 8px 18px;
                    font-weight: 600;
                    cursor: pointer;
                };
            };
        };

        .absoluteP {
            width: 150px;
            position: absolute;
            left: 0;
            bottom: -115px;
        }
    };

    .thirdSection {
        width: 100%;
        padding: 40px 10px;
        position: relative;
    
        .thirdSectionInnerWrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            
            .thirdSectionACont{
                width: 100%;
                margin-right: 0;
                padding-left: 0;
                display: flex;
                justify-content: center;
                flex-direction: column;
            
                article {
                    h1 {
                        font-size: 20px;
                        font-weight: 600;
                        color: #0B3A5A;
                    };
            
                    p {
                        width: 100%;
                        font-size: 12px
                    };
            
                    button {
                        background:  #0B3A5A;
                        color: #f7f7f7;
                        border: none;
                        font-size: 16px;
                        padding: 10px 20px;
                        font-weight: 600;
                        margin-top: 0;
                        cursor: pointer;
                    };
                };
            };
    
            .imageWrapper {
                width: 100%;
                margin-top: 20px;
                display: flex;
                justify-content: center;

                img {
                    width: 85%
                };
            };
        };
        
        .thirdPoly {
            width: 130px;
            position: absolute;
            left: -60px;
            bottom: 30px;
        };
    };

    .fourthSection {
        background: #f7f7f7;
        padding: 40px 15px;
    
        h1 {
            text-align: center;
            color: #0B3A5A;
            font-size: 20px;
            font-weight: 600;
        };
    
        .fourthSecGrid {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: max-content;
            column-gap: 15px;
            row-gap: 20px;
            margin: 0 auto;
            padding-top: 10px;
    
            .OfferComp {
                background: #FFFFFF;
                box-shadow: 0px 0px 0px 3px rgba(11, 58, 90, 0.1), 0px 1px 4px rgba(11, 58, 90, 0.01);
                border-radius: 16px;
                padding: 10px 10px 5px 10px ;

                img {
                    width: 25px;
                };
    
                h3 {
                    margin-top: 10px;
                    font-size: 14px;
                };
    
                p {
                    font-size: 12px !important;
                    color: #494848;
                    line-height: 1.5;
                };
            };
        };
    };

    .fifthSection {
        padding: 50px 0 30px 0;
    
        h1 {
            color: #0B3A5A;
            font-size: 20px;
            font-weight: 600;
            text-align: center;
        };
    
        .shortDash {
            display: block;
            background: #F3BA2F;
            height: 2px;
            width: 70px;
            margin: 10px auto;
        };
    
        .lHeading {
            width: 100%;
            margin: 0 auto;
            font-size: 10px;
            text-align: center;
        };
    
        .questContainer {
            background: #F1F1F1;
            box-shadow: 0px 0px 0px 1px rgba(27, 31, 35, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.02);
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: 15px auto;
            color: #2F2E2E;
    
            button {
                width: 100%;
                height: 50px;
                background: #F1F1F1;
                border: none;
                text-align: left;
                padding: 0 5px;
                font-size: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #2F2E2E;
                cursor: pointer;
            };
        };
    }

    .sixthSection {
        padding: 30px 0;

        h1 {
            color: #0B3A5A;
            font-size: 20px;
            font-weight: 600;
            text-align: center;
        };
        .remarkItemsWrapper {
            padding: 15px 10px;
            display: flex;
            gap: 20px;
            overflow-x: hidden;

            .remarkWrapper {
                width: 300px;
                min-width: 300px;
                background: #FFFFFF;
                box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
                border-radius: 5px;
                padding: 15px 15px 0 15px;

                h3 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 16px;
                    letter-spacing: 0.5px;
                    text-transform: capitalize;
                    color: #000000;
                };

                img {
                    width: 16px;
                    margin-right: 5px;
                };

                p {
                    margin-top: 15px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 20px;
                    letter-spacing: 0.5px;
                    text-transform: capitalize;
                    color: #5B6674;
                };
            };
        };
        
        .arrowWrapper {
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;
            gap: 20px;

            span {
                height: 24px;
                width: 24px;
                background: #E5E5E5;
                
                display: flex;
                align-items: center;
                justify-content: center;
            };
        };
    };

    .seventhSection {
            margin-top: 50px;

        .seventhSectionInnerWrapper {
            width: 90%;
            background: #0B3A5A;
            border-radius: 15px;
            padding: 10px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;

            .whiteCircleRing {
                width: 35px;
                position: absolute;
                top: 0;
                right: 0;
            };
        
            h1 {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 36px;
                text-align: center;
                text-transform: capitalize;
                color: #F1F1F1;
            };
        
            form {
                width: 90%;
                .inputAndButtonWrapper {
                    width: 100%;
                    border: 1px solid #9e9e9e;
                    box-sizing: border-box;
                    border-radius: 3px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
        
                    input {
                        width: calc(100% - 70px);
                        border: none;
                        background: transparent;
                        padding: 3px 10px;
                        color: #F1F1F1;
                        font-size: 11px;
        
                        &:focus {
                            outline: none
                        };
                    };
        
                    button {
                        width: 70px;
                        border: none;
                        background: #FF9900;
                        color: #F7F7F7;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 11px;
                        line-height: 24px;
                        letter-spacing: 0.5px;
                        padding: 2px 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    };
                };
            };
        };
    };

    .eightSection {
        margin-top: 70px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;


        h1 {
            color: #0B3A5A;
            font-size: 20px;
            font-weight: 600;
            line-height: 1.3;
            text-align: center
        };

        .blogItemsWrapper {
                width: 100%;
                margin: 20px auto;
                padding-bottom: 10px;
                display: grid;
                grid-template-columns: 100%;
                grid-template-rows: min-content;
                gap: 25px;

            .blogItem {
                background: #FFFFFF;            
                box-shadow: 0px 10px 36px rgba(0, 0, 0, 0.16), 0px 0px 0px 1px rgba(0, 0, 0, 0.06);
                padding-bottom: 20px;
                display: flex;
                flex-direction: column;

                .titleAndSubTitleWrapper {
                    padding: 10px 10px 0 10px;
                    display: flex;
                    flex-direction: column;

                    strong {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: 0.15px;
                        text-transform: capitalize;
                        color: #2F2E2E;
                    };
                    p {
                        margin-top: 10px;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 9px;
                        line-height: 17px;
                        letter-spacing: 0.5px;
                        text-transform: capitalize;
                        color: #494848;
                    };
                };

                .readMoreButton {
                    margin-left: 10px;
                    margin-top: 0;
                    align-self: flex-start;
                    background: #0B3A5A;
                    border: 3px solid #0B3A5A;
                    box-sizing: border-box;
                    border-radius: 3px;
                    color: #FFFFFF;
                    cursor: pointer;
                    font-size: 8px;
                    padding: 6px 8px;
                };
            };
        };
    };
}