.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
    position: fixed;
    z-index: 9999999999;
    top: 0;
    left: 0;
}
.loader span {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: var(--primary-color-blue);
    animation: enlarge 1s linear infinite;
    margin: 0 2px;
}
.loader span:nth-child(2){
    animation-delay: 0.25s;
}
.loader span:nth-child(3){
    animation-delay: 0.5s;
}
.loader span:nth-child(4){
    animation-delay: 0.75s;
}
@keyframes enlarge {
    from {
     transform: translateY(0); 
    }
    to {
    transform: translateY(50%); 
    }
}
