.footerCont {
    background: black;
    padding: 20px 5%;
    color: #fff;
}
.topFooter {
    display: flex;
    padding: 15px 0;
}
.topFooter h3 {
    font-size: 16px;
};
.topFooter li{
    list-style: none;
}
.topFooter a {
    color: #f7f7f7;
    text-decoration: none;
}
.topFooter > div:nth-child(1) {
    flex: 50%;
    width: 50%;
    padding-right: 10px;
}
.topFooter > div:nth-child(1) ul{
    padding-top: 15px;
}
.topFooter > div:nth-child(1) li{
    padding: 5px 0;
    display: flex;
    align-items: center;
    font-size: 14px;
}
.topFooter > div:nth-child(1) li:nth-child(1) img{
    padding-right: 5px;
}

.topFooter > div:nth-child(1) li:nth-child(2) img{
    padding-left: 3px;
    padding-right: 8px;
}
.topFooter > div:nth-child(1) li:nth-child(3) img{
    padding-left: 2px;
    padding-right: 8px;
}
.topFooter > div:nth-child(2) {
    width: 25%;
    flex: 25%;
    padding-left: 20px;
}
.topFooter > div:nth-child(3) {
    width: 25%;
    flex: 25%;
}
.topFooter > div:nth-child(2) a {
    padding: 15px 0;
    font-size: 14px;
}
.socialIcons {
    display: flex;
    width: 150px;
    justify-content: space-between;
    padding-top: 10px;
}
.socialIcons span{
    border: 1px solid #FFFFFF;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bottomFooter {
    text-align: center;
    font-size: 14px;
    padding-top: 10px;
}







@media screen and (max-width: 575px) {
    .footerCont {
        background: black;
        padding: 20px 5%;
        color: #fff;
       
        .topFooter {
            display: flex;
            flex-direction: column;
            padding: 15px 0;

            div {
                width: 100%;
                padding: 10px 0;

                ul {
                    padding-left: 0;
                    li {
                        font-size: 12px;

                        img {
                            width: 20px
                        };
                    };
                };

                a {
                    font-size: 13px;
                    text-decoration: none;
                };

                .socialIcons {
                    display: flex;
                    justify-content: flex-start;

                    span {
                        margin-right: 15px;
                        border: 1px solid #FFFFFF;
                        height: 25px;
                        width: 25px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 13px
                        };

                        &:nth-child(3) {
                            img {
                                width: 8px
                            };
                        };
                    };
                };

            };
            
        };
    };

    .bottomFooter {
        text-align: center;
        font-size: 10px;
        padding-top: 10px;
    }
}

@media screen and (max-width: 375px) {
    .footerCont {
        background: black;
        padding: 20px 5%;
        color: #fff;
       
        .topFooter {
            display: flex;
            flex-direction: column;
            padding: 15px 0;

            div {
                width: 100%;
                padding: 10px 0;

                h3 {
                    font-size: 10px
                };

                ul {
                    li {
                        font-size: 11px;

                        img {
                            width: 20px
                        };
                    };
                };

                a {
                    font-size: 11px;
                };

                .socialIcons {
                    display: flex;
                    justify-content: flex-start;

                    span {
                        margin-right: 15px;
                        border: 1px solid #FFFFFF;
                        height: 20px;
                        width: 20px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 6px
                        };

                        &:nth-child(3) {
                            img {
                                width: 8px
                            };
                        };
                    };
                };
            };
            
        };
    };

    .bottomFooter {
        text-align: center;
        font-size: 10px;
        padding-top: 10px;
    }
};