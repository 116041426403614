.mainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.subContainer {
    display: flex;
    width: 70%;
    border-radius: 30px;
    overflow: hidden;
    box-shadow: 0 0 20px #eee;
    position: relative;
    padding: 40px 60px;
}
.subContainer p {
    text-align: center;
}
.subContainer form {
    width: 100%;
}
.subContainer form h2 {
    text-align: center;
    color: var(--neutral-black);
}
.inputSection {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}
.inputContainer {
    width: 48%;
    display: flex;
    flex-direction: column;
}
.inputContainer label {
    padding: 5px 0;
}
.inputContainer input {
    border: 1px solid var(--border-color-1);
    border-radius: 15px;
    height: 45px;
    outline: none;
    padding: 0 15px;
}
.buttonCont {
    display: flex;
    justify-content: center;
}
.submitButton {
    border-radius: 10px;
    border: none;
    background: var(--primary-color-blue);
    color: #fff;
    height: 40px;
    width: 70%;
    text-align: center;
    min-width: 200px;
    max-width: 350px;
    margin: 25px 0;
    cursor: pointer;
}